






















































































import { Component, Vue } from "vue-property-decorator";
import MyList from "@/components/MyList.vue";
import Mixin from "@/js/mixins";

@Component({ components: { MyList }, mixins: [Mixin] })
export default class OrderList extends Vue {
  public tab_list = [
    { name: "全部订单", value: "" },
    { name: "待处理", value: "101" },
    { name: "待发货", value: "102" },
    { name: "已发货", value: "103" },
    { name: "已完成", value: "104" }
  ]; // 头部导航
  public active = 0; // 头部导航选中位置
  public user_id = "";
  public search = ""; // 搜索框的内容
  public search_value = ""; // 点搜索按钮后搜索点内容
  public list: any[] = []; // 数据的列表
  public refresh = 0; // 页面允许刷新（为了list的改变）

  clearList() {
    this.list = [];
  }
  setList(list: any[]) {
    this.list.push(...list);
  }

  init() {
    this.user_id = String(this.$route.query.user_id || "");
    this.active = Number(this.$route.query.active || 0);
    this.search = "";
    this.search_value = "";
    this.list = [];
    this.refresh++;
  }
}
